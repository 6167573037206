<template>
    <div class="resume">
        <div class="title">
            <div class="sub-title">简历中心</div>
            <!-- <div class="sub-filtter"><i class="el-icon-delete"></i> 清空索索条件</div> -->
        </div>
        <el-card class="box-card">
            <!-- <el-row :gutter="20" style="margin-bottom: 20px;">
                <el-col :span="10">
                    <div class="flex">
                        <el-input v-model="searchForm.name" placeholder="请输入内容" style="margin-left: -1px;" size="small"></el-input>
                        <el-button class="active" style="margin-left: -3px;" size="small">搜索</el-button>
                    </div>
                </el-col>
            </el-row> -->
            <el-row style="margin-bottom: 20px">
                <el-col :span="10">
                    <div class="search flex">
                        <input v-model="searchForm.keyWord" placeholder="请输入搜索职位关键词，搜索心仪的人才" @keyup.enter="handleFilter"/>
                        <div class="active button4" @click="handleFilter">搜索</div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-bottom: 20px;">
                <el-col :span="4">
                    <el-select v-model="searchForm.age" placeholder="请选择年龄" size="mini" @change="filterList" clearable>
                        <el-option
                        v-for="item in ageList"
                        :key="item.desc"
                        :label="`${item.desc}岁`"
                        :value="item.desc">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="searchForm.gender" placeholder="请选择性别" size="mini" @change="filterList" clearable>
                        <el-option
                        v-for="item in sexList"
                        :key="item.id"
                        :label="item.desc"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="searchForm.education" placeholder="请选择学历" size="mini" @change="filterList" clearable>
                        <el-option
                        v-for="item in educationList"
                        :key="item.id"
                        :label="item.desc"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="searchForm.salary" placeholder="请选择薪资范围" size="mini" @change="filterList" clearable>
                        <el-option
                        v-for="item in salesList"
                        :key="item.id"
                        :label="item.desc"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="searchForm.workExperience" placeholder="请选择工作年限" size="mini" @change="filterList" clearable>
                        <el-option
                        v-for="item in workList"
                        :key="item.id"
                        :label="item.desc"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
               
            </el-row>
            <el-row>
                <!-- <el-col :span="4" style="margin-bottom: 20px;">
                    <el-select v-model="searchForm" placeholder="请选择更新时间" size="small" @change="queryResumeList">
                        <el-option
                        v-for="item in updataTimeList"
                        :key="item.id"
                        :label="item.desc"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-col> -->
                <el-col :span="4" style="margin-bottom: 20px;">
                    <el-cascader
                        v-model="searchForm.industry"
                        placeholder="请选择职位"
                        @change="handleIndustryChange"
                        :options="jobList"
                        :props="jobProps"
                        clearable
                        size="mini">
                    </el-cascader>
                    <!-- <el-select v-model="searchForm.industry" placeholder="请选择行业" size="small" @change="queryResumeList">
                        <el-option
                        v-for="item in industryList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select> -->
                </el-col>
                 <el-col :span="16">
                    <Changecity @getList="handleAreaChange" :filed="id"></Changecity>
                </el-col>
                <!-- <el-col :span="2">
                    <el-checkbox v-model="searchForm.avata">有头像</el-checkbox>
                </el-col>
                <el-col :span="2">
                    <el-checkbox v-model="searchForm.avata">已认证</el-checkbox>
                </el-col>
                <el-col :span="2">
                    <el-checkbox v-model="searchForm.avata">有作品</el-checkbox>
                </el-col> -->
            </el-row>
            <div class="menu-center">
                <div class="number">
                    <div class="number-left">
                        <!-- <el-checkbox v-model="searchForm.havePic" @change="queryResumeList">有头像</el-checkbox> -->
                        <!-- <el-checkbox v-model="searchForm.avata" @change="queryResumeList">手机认证</el-checkbox> -->
                        <!-- <el-checkbox v-model="searchForm.haveWorks" @change="queryResumeList">有作品/照片</el-checkbox> -->
                    </div>
                    <div class="number-right" @click="clearSearch">
                         <div class="sub-filtter"><i class="el-icon-delete"></i> 清空搜索条件</div>
                    </div>
                </div>
                <div class="number-table">
                    <div class="number-table-item" v-for="item in queryAllResumeList" :key="item.id">
                        <!-- <div class="avata">
                            <img :src="item.picUrl?item.picUrl:require('../../../assets/img/company/base_logo.png')" alt=""  />                        
                        </div> -->
                        <div class="avata" v-if="item.gender=='男'">
                            <img  :src="item.heardImg||require('../../../assets/img/company/base_logo.png')"  />                        
                        </div>
                        <div class="avata" v-if="item.gender=='女'">
                            <img  :src="item.heardImg||require('../../../assets/img/company/headgirl.png')"  />                        
                        </div>
                        <div class="user-info">
                            <!-- <el-popover placement="right-top" trigger="hover" width="300">
                                <div class="resume" style="width: 300px">
                                    <div class="done flex">
                                        简历完成度：88% 
                                        <div class="progress">
                                            <div class="progress-do" :style="{'width': percentage}"></div>
                                        </div>
                                    </div>
                                    <div class="name">{{item.name}}  ({{item.gender}} , {{item.age}}岁)</div>
                                    <div class="hope-job">
                                        <p>期盼职位：{{item.expectPost}}</p>
                                        <p>期望薪资：{{item.expectSalary}}</p>
                                        <p>最高学历：{{item.eduction}}</p>
                                    </div>
                                    <div class="job-list">
                                        <p>工作了<span class="tips">11年5个月</span>,做了<span class="tips">4</span>份工作</p>
                                        <div class="conpany">
                                            <el-tag type="info" size="small">趣星传媒</el-tag>
                                            <el-tag type="info" size="small">趣星传媒</el-tag>
                                            <el-tag type="info" size="small">趣星传媒</el-tag>
                                            <el-tag type="info" size="small">趣星传媒</el-tag>
                                            <el-tag type="info" size="small">趣星传媒</el-tag>
                                            <el-tag type="info" size="small">趣星传媒</el-tag>
                                            <el-tag type="info" size="small">趣星传媒</el-tag>
                                            <el-tag type="info" size="small">趣星传媒</el-tag>
                                            <el-tag type="info" size="small">趣星传媒</el-tag>
                                        </div>
                                        <p>求职状态: <span>{{item.nowStatus}}</span></p>
                                        <p>到岗时间: <span>{{item.arrivalTime}}</span></p>
                                        <p>籍贯: <span>{{item.nativePlace}}</span></p>
                                    </div>
                                </div>
                                <div class="user-info-wage" slot="reference">
                                    <p class="name">{{item.name}} {{item.gender}} · {{item.age}}岁 · {{item.eduction}} </p>
                                     <div class="tags-warpper">
                                        <div class="tags">
                                            <el-tag type="success" size="small" v-for="(row, index) in item.tags" :key="`tag${index}`">{{row}}</el-tag>
                                        </div>
                                        <div class="no-tag"></div>
                                    </div>
                                </div>
                            </el-popover>  -->
                            <div class="user-info-wage" slot="reference">
                                <p class="name">{{item.name}} {{item.gender}} · {{item.age}}岁 · {{item.education||'无'}} </p>
                                    <div class="tags-warpper">
                                    <!-- <div class="tags">
                                        <el-tag type="success" size="small" v-for="(row, index) in item.tags" :key="`tag${index}`">{{row}}</el-tag>
                                    </div> -->
                                    <div class="no-tag">
                                        求职状态: <span>{{item.nowStatus}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="user-info">
                            <p class="name">semball 男 · 23岁 · 本科 · 江西上饶 </p>
                            <div class="tags-warpper">
                                <div class="tags">
                                    <el-tag size="small">标签一</el-tag>
                                    <el-tag type="success" size="small">标签二</el-tag>
                                    <el-tag type="info" size="small">标签三</el-tag>
                                    <el-tag type="warning" size="small">标签四</el-tag>
                                    <el-tag type="danger" size="small">标签五</el-tag>
                                </div>
                                <div class="no-tag"></div>
                            </div>
                        </div> -->
                        <div class="position">
                            <div class="position-name">
                                {{item.industry}} <span class="split">|</span> 
                                <span class="position-name-desc">工作经验: {{item.workExperience}}</span>
                            </div>
                            <div class="position-time">
                                求职意向:   
                                <span class="position-time-desc">{{item.expectPost}}</span>
                            </div>
                        </div>
                        <div class="wage">
                            <div class="wage-info">期望薪资</div>
                            <div class="wage-number">{{item.expectSalary}}</div>
                        </div>
                        <div class="download">
                            <el-button class="active" size="small" @click="routeToresumeInfo(item.id)">查看简历</el-button>
                            <!-- <el-button class="active" size="mini">下载简历</el-button> -->
                            <!-- <div class="success button1 border">查看简历</div>
                            <div class="active button1 border">下载简历</div>
                            <div class="collection button1 border">收藏简历</div> -->
                        </div>
                        <!-- <div class="download">
                            <el-button class="active">下载简历</el-button>
                        </div> -->
                    </div>
                </div>
            </div>
            <app-pagination @handleSizeChange="handleSize" @handleCurrentChange="handleCurrent" :total="total"></app-pagination>
            <!-- <div style="padding-top:400px"></div> -->
        </el-card>
    </div>
</template>
<script>
import AppPagination from '../../../components/pagination/AppPagination'
import companyRequest from '../../../api/company'
import{mapState} from 'vuex'
import Changecity from '@/components/Changecity.vue'
export default {
    created() {
        this.searchForm.keyWord = window.localStorage.getItem('searchJob')
    },
    mounted() {
        this.getIndustry()
        this.queryIndustry()
        this.$nextTick(() => {
            this.queryResumeList()
        })
    },
    components: {
       AppPagination,
       Changecity
    },
    computed: {
        ...mapState(['searchJob'])
    },
    watch: {
        searchJob(val) {
            this.searchForm.keyWord = val
            console.log('val', val)
            console.log('keyWord', this.searchForm.keyWord)
            if (this.searchForm.keyWord) {
                this.searchForm.industry = []
                this.queryResumeList()
                // this.$message.error('查询不能为空')
                // renturn
            } else {
                //  this.queryResumeList()
            }
            // this.searchForm.industry = ''
           
        }
    },
    data() {
        return {
            // loading: true,
            jobId: null, //简历id
            score: null, //查看简历扣除积分
            queryAllResumeList: [], //简历列表
            industryList: [], //行业分类
            total: 0,
            jobList: [],
            percentage: '88%', //简历进度
            //搜索条件
            searchForm: {
                orgId:null,
                keyWord: '', //职位
                pageNo: 1,
                pageSize: 10,
                salary: null, //薪资
                gender: null, //性别
                industry: [], //行业
                education: '', //学历
                workExperience: null, //工作经验
                age: '', //年龄选择
                ageStart: '', //开始年龄
                ageEnd: '', //截止年龄
                // havePic: null, //有头像
                haveWorks: null //有作品
            },
            jobProps: {
                expandTrigger: 'hover',
                label: 'name',
                value: 'name',
                children: 'children'
            },
            ageList: [ //年龄选择列表
                {id: 1, desc: '16-20'},
                {id: 2, desc: '21-30'},
                {id: 3, desc: '31-40'},
                {id: 4, desc: '41-50'},
                {id: 5, desc: '50-100'},
            ],
            sexList: [ //性别列表
                {id:1, desc: '男'},
                {id:2, desc: '女'},
            ],
            educationList: [ //学历列表
                // {id: null, desc: '不限'},
                {id: 1, desc: '初中及以下'},
                {id: 2, desc: '高中'},
                {id: 3, desc: '中专/中技'},
                {id: 4, desc: '大专'},
                {id: 5, desc: '本科'},
                {id: 6, desc: '硕士'},
                {id: 7, desc: 'MBA/EMBA'},
                {id: 8, desc: '博士'},
            ],
            salesList: [ //薪资列表
                {id: 1, desc: '1000-2000元'},
                {id: 2, desc: '2000-3000元'},
                {id: 3, desc: '3000-5000元'},
                {id: 4, desc: '5000-8000元'},
                {id: 5, desc: '8000-12000元'},
                {id: 6, desc: '12000-15000元'},
                {id: 7, desc: '15000-20000元'},
                {id: 8, desc: '20000元以上'},
            ],
            workList: [ //工作经验
                // {id: 1, desc: '不限'},
                {id: 1, desc: '无经验'},
                {id: 2, desc: '应届生'},
                {id: 3, desc: '1年以下'},
                {id: 4, desc: '1-2年'},
                {id: 5, desc: '2-3年'},
                {id: 6, desc: '3-5年'},
                {id: 7, desc: '5-8年'},
                {id: 8, desc: '8-10年'},
                {id: 9, desc: '10年以上'},
            ],
            updataTimeList: [ //更新时间
                {id: 1, desc: '不限'},
                {id: 2, desc: '一天以内'},
                {id: 3, desc: '三天以内'},
                {id: 4, desc: '七天以内'},
            ]
        }
    },
    beforeRouteLeave (to, from, next) {
        const searchJob = ''
        this.$store.commit('changeSearchJob', {searchJob})
        window.localStorage.setItem('searchJob', searchJob)
        next()
    },
    methods: {
        handleAreaChange(data){
            this.searchForm.orgId=data
            this.searchForm.pageNo=1
            this.queryResumeList()
        },
        filterList() {
            this.searchForm.pageNo = 1
            this.queryResumeList()

        },
        //获取简历列表
        queryResumeList() {
            // console.log('this', this)
            const _this = this
            const obj = JSON.parse(JSON.stringify(this.searchForm))
            if (this.searchForm.age) {
                const arr = this.searchForm.age.split('-')
                obj.ageStart = arr[0]
                obj.ageEnd = arr[1]
            }
            if (this.searchForm.havePic) {
                obj.havePic = 1
            }else{
                obj.havePic = null
            }
            if (this.searchForm.haveWorks) {
                obj.haveWorks = 1
            }else{
                obj.haveWorks = null
            }
            obj.industry = obj.industry.join(',')
            companyRequest.queryResumeList(obj).then((res) => {
                if (res.code === 200) {
                    // console.log('queryAllResumeList', res.data.list)
                    let list = res.data.list
                    // this.loading = false
                    // this.queryAllResumeList = JSON.parse(JSON.stringify(res.data.list))
                    this.total = res.data.total
                    // this.queryAllResumeList.forEach((item, index) => {
                    //     item.tags = item.tag.split(',')
                    // })
                    // debugger
                    list.forEach((item, index) => {
                        if(item.tag) {
                            item.tags = item.tag.split(',')
                        } else {
                            item.tags = []
                        }
                        // console.log('item', item)
                    })
                    // console.log('list', list)
                    this.queryAllResumeList = list
                }
            }).catch((err) => {
                // this.loading = false
                _this.queryAllResumeList = []
            })
        },
        handleFilter() {
            const searchJob = this.searchForm.keyWord
            // if (!searchJob) {
            //     this.$message.error('查询条件不能为空')
            //     return
            // }
            this.$store.commit('changeSearchJob', {searchJob})
            this.searchForm.pageNo = 1
            this.queryResumeList()
        },
        handleIndustryChange(val) {
            this.searchForm.keyWord = ''
            const searchJob = ''
            this.$store.commit('changeSearchJob', {searchJob})
            this.searchForm.pageNo = 1
            this.queryResumeList()
            console.log('val', val)
        },
        //获取职位分类
        queryIndustry() {
            companyRequest.queryAllIndustry().then((res) => {
                if (res.code === 200) {
                    this.jobList = res.data
                    // console.log('jobList', this.jobList)
                }
            })
        },
        //清空筛选条件
        clearSearch() {
            this.searchForm.keyWord = ''
            this.searchForm.pageNo = 1
            this.searchForm.pageSize = 10
            this.searchForm.salary = null
            this.searchForm.gender = null
            this.searchForm.industry = []
            this.searchForm.education = null
            this.searchForm.workExperience = null
            this.searchForm.age = ''
            this.searchForm.ageStart = ''
            this.searchForm.ageEnd = ''
            this.searchForm.havePic = null
            this.searchForm.haveWorks = null
            this.queryResumeList()
            // console.log(1111)
        },
        //跳转详细简历
        routeToresumeInfo(id) {
            this.jobId = id 
            //查询用户是否需要消耗积分
            this.checkResume().then((res) => {
                // console.log('res', res)
                this.$confirm(`此操作将消耗${this.score}积分查看简历, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                       companyRequest. checkResumeIntegral().then((res) => {
                           if (res.success) {
                               this.$router.push({
                                    path: '/company/resumeInfo',
                                    query: {
                                        id: this.jobId
                                    }
                                })
                           }
                       }).catch((err) => {
                           this.$message.error(err)
                       })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消查看'
                        })        
                    })
            })
            // this.$router.push({
            //     path: '/company/resumeInfo',
            //     query: {
            //         id: id
            //     }
            // })
        },
        checkResume() {
            return new Promise((reslove, reject) => {
                companyRequest.checkResume({resumeId: this.jobId}).then((res) => {
                    if (res.code === 200) {
                        this.score = res.data.number
                        if (res.data.status) { //true看过
                            this.$router.push({
                                path: '/company/resumeInfo',
                                query: {
                                    id: this.jobId
                                }
                            })
                        } else { //没看过
                            reslove(res)
                        }
                    } else {
                        reject()
                    }
                })
            })
        },
        //获取公司行业分类
        getIndustry() {
            companyRequest.getIndustry().then((res) => {
                if (res.code === 200) {
                    this.industryList = res.data
                }
            })
        },
        search() {
            // console.log(this.searchForm)
        },
        handleSize(size) {
            this.searchForm.pageSize = size
            this.searchForm.pageNo = 1
            this.queryResumeList()
        },
        handleCurrent(num) {
            this.searchForm.pageNo = num
            this.queryResumeList()
        },
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
    .resume{
        height: 100%;
    }
    .search{
        input{
            display: block;
            width: 300px;
            height: 30px;
            padding: 0 12px;
            border: 1px solid #eee;
            outline: none;
        }
        .button4{
            width: 60px;
            height: 32px;
            // background: $blur;
            background: $main;
            line-height: 32px;
            cursor: pointer;
            text-align: center;
            user-select: none;
            &:hover{
                // background: #3d8de1;
                background:$hover;
            }
        }
    }
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        // .sub-title{
        //     font-size: 20px;
        //     letter-spacing: 0;
        //     color: #111;
        // }
        .box-card{
            height: calc(100% - 100px);
        }
        .button1{
            padding: 8px 12px;
        }
        .sub-filtter{
            font-size: 14px;
            cursor: pointer;
            margin-right: 30px;
            line-height: 35px;
            width: 123px;
            height: 35px;
            text-align: right;
            color: #555;
            &:hover{
                color: $main;
            }
        } 
    }
</style>
<style lang="scss">
@import '../../../assets/css/global.scss';
.menu-center{
    width: 100%;
    margin-top: 10px;
    // margin-bottom: 400px;
    .number{
    border: 1px solid #eee;
    box-sizing: border-box;
    margin-bottom: -1px;
    padding-left: 20px;
    height: 40px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .number-right{
        margin-right: 20px;
        &:hover{
            color: $main;
            cursor: pointer;
        }
    }
    }
    .number-table{
        border: 1px solid #eee;
        margin-bottom: 20px;
      .number-table-item{
            padding: 20px 20px;
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: flex-start;
            &:hover{
                background: #f6f6f6;
            }
        .avata{
            width: 10%;
            img{
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }
        .user-info{
            width: 30%;
            display: flex;
            flex-direction: column;
            min-height: 60px;
            justify-content: space-between;
            .name{
                color: #333;
                font-size: 13px;
                font-weight: 700;
            }
            .tags-warpper{
                margin-top: 30px;
                .tags{
                    .el-tag{
                        margin-right: 10px;
                        margin-bottom: 5px;
                    }
                }
                .no-tag{
                    color: #555;
                    font-size: 12px;
                }
            }
        }
        .user-info-wage{
            display: flex;
            flex-direction: column;
            min-height: 60px;
            justify-content: space-between;
        }
        .resume{
            max-width: 500px;
            .done{
                font-size: 12px;
                color: #828282;
               justify-content: flex-end;
               .progress{
                   margin-left: 10px;
                   margin-top: 2px;
                   width: 100px;
                   height: 10px;
                   background: #e1e1e1;
                   .progress-do{
                       height: 100%;
                       background: $main;
                   }
               } 
            }
            .name{
                font-size: 16px;
                font-weight: 700;
                line-height: 46px;
                color: #282828;
                border-bottom: 1px solid #eee;
            }
            .hope-job{
                padding: 5px 0;
                border-bottom: 1px solid #eee;
                p{
                    font-size: 12px;
                    line-height: 20px;
                    color: #747474;
                }
            }
            .job-list{
                padding: 5px 0;
                border-bottom: 1px solid #eee;
                p{
                    line-height: 24px;
                }
              .tips{
                  color: #ff0522;
                  font-weight: 700;
              }
              .conpany{
                  padding-top: 10px;
                  .el-tag{
                      margin: 5px 10px 5px 0;
                  }
              }  
            }
        }
        .position{
            width: 30%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            min-height: 60px;
            .split{
                color: #999;
                font-size: 12px;
                padding: 0 10px;
            }
            .position-name{
                font-size: 14px;
                color: #111;
                .position-name-desc{
                    font-size: 14px;
                    color: #333;
                }
            }
            .position-time{
                font-size: 12px;
                color: #555;
                .position-time-desc{
                    color: $main;
                }
            }
        }
        .wage{
            width: 10%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            align-self: center;
            .wage-info{
                font-size: 12px;
                color: #555;
            }
            .wage-number{
                margin-top: 7px;
                letter-spacing: 2px;
                font-weight: 550;
            }
        }
        .download{
            width: 20%;
            box-sizing: border-box;
            display: flex;
            align-self: center;
            padding-left: 90px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            div{
                cursor: pointer;
            }
            .el-button{
                margin-left: 0;
                margin-bottom: 5px;
            }
            
            // .el-button{
            //     width: 40%;
            //     &:hover{
            //         background: $hover;
            //     }
            // }
        }  
      }  
    }
}

</style>
